<template>
  <div>
    <div class="page inner-container reset">
      <div class="page-wrap">
        <div class="row justify-content-center">
          <resetPasswordForm></resetPasswordForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resetPasswordForm from "@/views/Login/parts/resetPasswordForm";

export default {
  name: "ResetPasswordIndex",
  components: { resetPasswordForm },

  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss"></style>
